import axios from "axios"
export default {
    data() {
        return {
            weburl: "https://www.rayscloud.cn/webapi",
            defaultImg: require('../../assets/resource/news-1.jpg'),
            siteId:localStorage.getItem("siteId"),
            menuId:localStorage.getItem("menuId"),
            childId: localStorage.getItem("childId"),
            childIds:0,
            menu: {},
            navList: [],
            newList: [],
        }
    },
    methods: {
        setmenuId(name) {
            this.childIds = name;
            let menu = this.navList.find(x => x.serId == name);
            localStorage.setItem("secondMenu", JSON.stringify(menu));
            this.getContent();
        },
        toContant(code,linkUrl) {
            if(linkUrl){
                var link = linkUrl
                window.open(link, '_blank');
            }else{
                let route = this.$router.resolve({
                    path: `/productContent?code=${code}`
                })
                window.open(route.href, '_blank');
            }
        },
        getchild() {
            axios.get(`${this.weburl}/api/Site/Menu/${this.siteId}/${this.childId}`)
                .then((res) => {
                    if (res.status == 200) {
                        this.navList = res.data.data;
                        if (this.navList.length > 0) {
                            this.childIds = this.navList[0].serId;
                            this.getContent();
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getContent() {
            let searchstr = {
                "page": 1,
                "pageSize": 999,
                "siteId": this.siteId,
                "menu": this.menuId,
                "child": this.childId,
                "childs": this.childIds,
                "form": "font"
            };
            axios.post(`${this.weburl}/api/Cont/list`, searchstr)
                .then((res) => {
                    if (res.status == 200) {
                        this.newList = res.data.data.data;
                        console.log(this.newList)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
    created() {
        this.getchild();
    },
}